<template>
    <!-- 分成上下两个部分，上面可以输入大段文本，点“检测”按钮，下面显示检测结果 -->
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <h1 class="display-1">文本检测</h1>
                    </v-card-title>
                    <v-card-text>
                        <v-textarea
                            v-model="content"
                            label="请输入文本"
                            rows="10"
                            outlined
                            dense
                        ></v-textarea>
                        <v-btn
                            @click="doCheck"
                            color="primary"
                            class="mt-2"
                            :loading="isSending"
                            :disabled="isSending"
                        >
                            检测
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <h2 class="headline">检测结果</h2>
                    </v-card-title>
                    <v-card-text>
                        <pre>{{ result }}</pre>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import service from '@/utils/request';

export default {
    name: "Talk",
    data() {
        return {
            content: '',
            result: '',
            isSending: false,
        };
    },
    methods: {
        doCheck() {
            if (this.isSending) {
                return;
            }
            this.isSending = true;

            const formData = new FormData();
            formData.append('content', this.content);

            this.result = '检测中...';
            service.post("/wikiapi/check", formData).then(response => {
                this.result = response.data;
                this.isSending = false;
            }).catch(() => {
                this.result = '检测失败，请重试';
                this.isSending = false;
            });
        },
    },
};
</script>

<style scoped>
.v-container {
    margin-top: 20px;
}
.v-card {
    margin-bottom: 20px;
}
</style>